<template>
  <v-card class="calendar-view" elevation="2">
    <v-sheet class="c-view-toolbar">
      <v-toolbar flat class="c-view-toolbar-1">
        <v-btn elevation="0" class="mr-4" @click="setToday">
          Today
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar" class="title-date">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <div style="width:200px; margin-right:5px;" class="room-dropdown">
          <v-select
            v-model="paramAPI.building_id"
            :items="getBuildingDropdown"
            item-text="name"
            item-value="id"
            return-id
            class="elevation-0"
            hide-no-data
            hide-details
            label="Building"
            return
            dense
            outlined
          ></v-select>
        </div> -->
        <div style="width:200px;" class="room-dropdown">
          <v-select
            v-model="paramAPI.room_id"
            :items="getRoomDropdown"
            item-text="name"
            item-value="id"
            return-id
            :item-disabled="disableItem"
            class="mx-4 elevation-0"
            hide-no-data
            hide-details
            label="Select Room"
            return
            dense
            @change="updateRange"
            outlined
          ></v-select>
        </div>
        <!-- <div style="width:200px;" class="room-dropdown">
          <v-select
            outlined
            v-model="paramAPI.keyword"
            :items="[
              '',
              'Merak 1',
              'Merak 2',
              'Merak 3',
              'Elang',
              'Cenderawasih'
            ]"
            cache-items
            class="mx-4 elevation-0"
            hide-no-data
            hide-details
            label="Select Room"
            return
            dense
            @change="updateRange"
          ></v-select>
        </div> -->
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="0" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <div flat class="c-view-toolbar-2">
        <v-toolbar-title
          v-if="$refs.calendar"
          class="title-date"
          style="margin-right:10px;"
        >
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <!-- <div style="width:110px;margin-right:5px;" class="room-dropdown">
          <v-select
            :items="getBuildingDropdown"
            item-text="name"
            item-value="id"
            return-id
            class="elevation-0"
            hide-no-data
            hide-details
            label="Building"
            return
            dense
            outlined
            clearable
          ></v-select>
        </div> -->
        <div style="width:110px;" class="room-dropdown">
          <v-select
            v-model="paramAPI.keyword"
            :items="[
              '',
              'Merak 1',
              'Merak 2',
              'Merak 3',
              'Elang',
              'Cenderawasih',
              'Rajawali'
            ]"
            class="elevation-0"
            hide-no-data
            hide-details
            label="Select Room"
            return
            dense
            @change="updateRange"
            outlined
          ></v-select>
        </div>
      </div>
    </v-sheet>

    <v-sheet class="c-view-content">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :event-color="getEventColor"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateRange"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div>
              <span style="font-weight:bold;">PIC :</span>
              <span v-html="selectedEvent.pic"></span>
            </div>
            <div>
              <span style="font-weight:bold;">Ruang : </span
              ><span v-html="selectedEvent.room"></span>
            </div>
            <div>
              <span style="font-weight:bold;">Jumlah Peserta :</span>
              <span v-html="selectedEvent.noAudience"></span>
            </div>
            <div>
              <span style="font-weight:bold;"> Keterangan :</span>
              <hr style="padding:0; margin:0;" />
              <span v-html="selectedEvent.description"></span>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
      <template v-slot:[`events.name`]="{ item }">
        {{ item }}
      </template>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'calendar',
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days'
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green'],
    paramAPI: {
      keyword: '',
      offset: 0,
      mode: 'calendar',
      limit: 100,
      building_id: 1,
      room_id: ''
    },
    getRoomDropdown: [],
    result: []
  }),
  watch: {
    'paramAPI.room_id'() {}
    // 'paramAPI.building_id'() {
    //   const id =
    //     this.paramAPI.building_id !== null ? this.paramAPI.building_id : 0
    //   this.roomDropdown(id)
    //     .then(res => {
    //       const newArr = [
    //         {
    //           id: '',
    //           level: '',
    //           name: ''
    //         }
    //       ]
    //       for (let i = 0; i < res.data.length; i++) {
    //         const obj = {
    //           id: res.data[i].id,
    //           level: res.data[i].level,
    //           name: res.data[i].name
    //         }
    //         newArr.push(obj)
    //       }
    //       this.getRoomDropdown = newArr
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // }
  },
  mounted() {
    this.dropdown()
    setTimeout(() => {
      this.$refs.calendar.checkChange()
    }, 500)
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapActions([
      'roomBookingListCalendar',
      'roomDropdown'
      // 'buildingDropdown'
    ]),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            title:
              pModalType === 'success'
                ? '<strong>Success</strong>'
                : `<strong>${pModalType}</strong>`,
            icon: pModalType,
            html: pStatusMsg,
            showConfirmButton: true,
            timer: 5000
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    disableItem(item) {
      return item.id === 6
    },
    dropdown() {
      // this.buildingDropdown()
      this.roomDropdown(this.getUserProfile)
        .then(res => {
          const newArr = [
            {
              id: '',
              level: '',
              name: ''
            }
          ]
          if (this.getUserProfile.sanqua_company_id !== 2) {
            for (let i = 0; i < res.data.length; i++) {
              if (
                res.data[i].id === 1 ||
                res.data[i].id === 2 ||
                res.data[i].id === 3 ||
                res.data[i].id === 4 ||
                res.data[i].id === 5 ||
                res.data[i].id === 10
              ) {
                const obj = {
                  id: res.data[i].id,
                  level: res.data[i].level,
                  name: res.data[i].name
                }
                newArr.push(obj)
              }
            }
          } else {
            for (let i = 0; i < res.data.length; i++) {
              const obj = {
                id: res.data[i].id,
                level: res.data[i].level,
                name: res.data[i].name
              }
              newArr.push(obj)
            }
          }
          // for (let i = 0; i < res.data.length; i++) {
          //   const obj = {
          //     id: res.data[i].id,
          //     level: res.data[i].level,
          //     name: res.data[i].name
          //   }
          //   newArr.push(obj)
          // }
          this.getRoomDropdown = newArr
        })
        .catch(err => {
          console.log(err)
        })
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange({ start, end }) {
      const events = []

      this.roomBookingListCalendar(this.paramAPI)
        .then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            const allDay = false
            const first = new Date(res.data.data[i].start_use).getTime()
            const second = new Date(res.data.data[i].end_use).getTime()

            // if (
            //   this.getUserProfile.employee.company.name ===
            //   res.data.data[i].company_name
            // ) {
            events.push({
              name:
                res.data.data[i].room !== null
                  ? 'R.' +
                    ' ' +
                    res.data.data[i].room.name +
                    ' ' +
                    '(' +
                    res.data.data[i].meeting_type +
                    ')'
                  : '-',
              start: first,
              end: second,
              color: this.colors[this.rnd(0, this.colors.length - 1)],
              timed: !allDay,
              room:
                res.data.data[i].room !== null
                  ? 'R.' + res.data.data[i].room.name
                  : '-',
              pic: res.data.data[i].employee_name,
              noAudience: res.data.data[i].number_of_attendance,
              description: res.data.data[i].description
            })
            // }
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  }
}
</script>

<style lang="scss" src="">
.calendar-view {
  width: 90%;
  .c-view-toolbar {
    height: 65px;

    .c-view-toolbar-2 {
      display: none;
    }
  }
  .c-view-content {
    height: 75vh;
  }
}
@media (max-width: 576px) {
  .calendar-view {
    width: 98%;
    .c-view-toolbar {
      height: 115px;
      .c-view-toolbar-1 {
        .title-date {
          display: none;
        }
        .room-dropdown {
          display: none;
        }
      }

      .c-view-toolbar-2 {
        display: flex;
        padding: 3px 16px;
        .title-date {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 5px;
          display: flex;
          font-size: 16px;
        }
        .room-dropdown {
          display: flex;
        }
      }
    }
    .c-view-content {
      height: 75vh;
    }
  }
}
</style>
