<template>
  <div class="fluid" id="calendarView">
    <div v-if="!getIsLoading" style="margin-top:20px;">
      <div v-if="permission.read_perm === 1">
        <div style="width:100%;">
          <Calendar style="margin:auto;" />
        </div>
      </div>
      <div v-else style="height:83vh; padding-top:50px;">
        <AccessDenied />
      </div>
    </div>
    <Loader v-else />
  </div>
</template>
<script>
import Calendar from '../../../components/meetingActivity/Calendar'
import AccessDenied from '../../../components/common/accessDenied/accessDenied'
import Loader from '../../../components/common/loader/CircularProgress'

import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    menu_key: 22,
    permission: {},
    application: {
      id: 9,
      name: 'Room Activity'
    },
    drawer: false,
    tabs: [
      { tab_name: 'Calendar', path: '/meeting-activity/calendar-view' },
      {
        tab_name: 'Request List',
        path: '/meeting-activity/request-list'
      },
      {
        tab_name: 'Make Request',
        path: '/meeting-activity/booking-form'
      },
      {
        tab_name: 'My Booking',
        path: '/meeting-activity/my-booking'
      }
    ]
  }),
  components: { AccessDenied, Calendar, Loader },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    this.setTabs(this.tabs)
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            setTimeout(() => {
              this.setIsLoading(false)
            }, 1500)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
              setTimeout(() => {
                this.setIsLoading(false)
              }, 1500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1500)
      }
    }
  },
  computed: {
    ...mapGetters([
      // 'getLogin'
      'getUserProfile',
      'getIsLoading'
    ])
  },
  methods: {
    ...mapActions(['menu_access']),
    ...mapMutations(['setIsLoading', 'setPermission', 'setTabs']),
    drawerSet(val) {
      this.drawer = val
    },
    home() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" src="">
#calendarView {
  position: relative;
  z-index: 2;
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  //   align-items: center;
  min-height: 90vh;
  margin-bottom: 10px;
}
</style>
