var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"calendar-view",attrs:{"elevation":"2"}},[_c('v-sheet',{staticClass:"c-view-toolbar"},[_c('v-toolbar',{staticClass:"c-view-toolbar-1",attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"elevation":"0"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',{staticClass:"title-date"},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"room-dropdown",staticStyle:{"width":"200px"}},[_c('v-select',{staticClass:"mx-4 elevation-0",attrs:{"items":_vm.getRoomDropdown,"item-text":"name","item-value":"id","return-id":"","item-disabled":_vm.disableItem,"hide-no-data":"","hide-details":"","label":"Select Room","return":"","dense":"","outlined":""},on:{"change":_vm.updateRange},model:{value:(_vm.paramAPI.room_id),callback:function ($$v) {_vm.$set(_vm.paramAPI, "room_id", $$v)},expression:"paramAPI.room_id"}})],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Day")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Week")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Month")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-title',[_vm._v("4 days")])],1)],1)],1)],1),_c('div',{staticClass:"c-view-toolbar-2",attrs:{"flat":""}},[(_vm.$refs.calendar)?_c('v-toolbar-title',{staticClass:"title-date",staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('div',{staticClass:"room-dropdown",staticStyle:{"width":"110px"}},[_c('v-select',{staticClass:"elevation-0",attrs:{"items":[
            '',
            'Merak 1',
            'Merak 2',
            'Merak 3',
            'Elang',
            'Cenderawasih',
            'Rajawali'
          ],"hide-no-data":"","hide-details":"","label":"Select Room","return":"","dense":"","outlined":""},on:{"change":_vm.updateRange},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1)],1)],1),_c('v-sheet',{staticClass:"c-view-content",scopedSlots:_vm._u([{key:"events.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}}],null,true)},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('v-card-text',[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("PIC :")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.pic)}})]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Ruang : ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.room)}})]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Jumlah Peserta :")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.noAudience)}})]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Keterangan :")]),_c('hr',{staticStyle:{"padding":"0","margin":"0"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.description)}})])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }